<template>
    <div id="distinguish" class="box around center">
        <!-- <img :src="imgSrc" alt="" class="img"> -->
          <el-carousel trigger="click" width="100%" v-if="imgs.length>0">
                    <el-carousel-item v-for="item in imgs" :key="item">
                        <img :src="item"/>
                    </el-carousel-item>
        </el-carousel>
        <div class="fu" v-else>
            <div class="div1 mB40"></div>
            <div class="textC">
                <svg class="icon fs20" aria-hidden="true" style="margin-right:5px">
                    <use xlink:href="#icontishi"></use>
                </svg>
                <span class="fs20">暂无桥梁识别信息，请</span>
                <span class="pointer fs20" style="color:#DBF804" @click="goClick()">上传</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                imgs:[],
                projectId:'',
                // imgSrc: require('../../assets/images/map/img1.jpg')
                imgSrc: ''
            }
        },
        created () {
            this.projectId = sessionStorage.getItem('projectId');
        },
        mounted () {    
            // var img = `${this.baseURL}base/baseProject/lastData/${this.projectId}`;
            // console.log('img', img);
            this.imgShow();
        },
        methods: {
            goClick(){
                this.$router.push('/proList')
            },

            imgShow() {
                this.$axios.get(`${this.baseURL}sys/sysImages/identification/${this.projectId}`)
                    .then(res=>{
                        console.log('识别图片',res)
                        var data= res.data.data;
                        console.log('data', data)

                        for(var i=0; i<data.length; i++){
                            console.log(111)
                            this.imgs.push(`${this.baseURL}sys/sysImages/img/`+data[i].id)
                            console.log(222)
                        }

                        if(data.length ==0){
                            this.imgs.push(this.imgSrc);
                        }

                        console.log('this.imgs', this.imgs)

                    })  
                    .catch(err=>{
                });
            }
        },

    }
</script>

<style lang="scss" scoped>
    #distinguish{
        width: 100%;
        height: 100%;
    }
    .img{
        width: 100%;
        height: 100%;
    }
     ::v-deep .el-carousel{
        width: 100%;
        height: 100%;
    }
    ::v-deep .el-carousel__container{
        height: 100%;
    }
    ::v-deep  .el-carousel__item  img{
        // width: 100%;
        height: 100%;
        margin: 0 auto;
    }
    .fu{
        // margin-top: 30%;
    }
    .div1{
        width: 315px;
        height: 191px;
        background: url('../../assets/images/noImage.png');
    }

</style>